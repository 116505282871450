import Constants from "../../constants/Constants";
import RequestService from "./RequestService";



let TransactionService =  {
    withdraw : async (data) => {
        let url = Constants.TRANSACTIONS.WITHDRAW_INITIATE;
        let req = await RequestService(url, true, data, true);
        console.log(req);
        return req;
    },
    fund : async (data) => {
        let url = Constants.TRANSACTIONS.FUND;
        let req = await RequestService(url, true, data, true);
        console.log(req);
        return req;
    },
    fetchTransactions : async () => {
        let url = Constants.TRANSACTIONS.FETCH;
        let req = await RequestService(url, false, null, true);
        console.log(req);
        return req;
    }
}

export default TransactionService;
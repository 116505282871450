import axios from "axios";
let development = false;
//layi
export default axios.create({
   //baseURL: "https://staging.api.rouzo.co/api",//process.env.BASE_URL\
   //baseURL : development ? "http://127.0.0.1:3001/api" : "https://rouzo-save.onrender.com/api",
   baseURL : development ? "http://127.0.0.1:3001/api" : "https://rouzo-save-backend-ufjn.onrender.com/api",
   //baseURL : development ? "http://127.0.0.1:3001/api" : "https://rouzo-save-backend.onrender.com/api",
   headers: {
      "Content-Type": "application/json"
   },
   withCredentials : false,
   
})

import Constants from "../../constants/Constants";
import axios from "../axios";
import authHeader from "./auth-header";
let opt = {
    headers: {
        "Authorization": `${authHeader()}`
    }
}
const AdminService = {
    fetchUsers : async () => {
        let url = Constants.ADMIN.FETCH_USERS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchTransactions : async () => {
        let url = Constants.ADMIN.FETCH_TRANSACTIONS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchSavingPlans: async () => {
        //console.log(reference);
        let url = Constants.ADMIN.FETCH_SAVINGPLANS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    }
};

export default AdminService;



import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";

import Layout from "../layouts/Layout";

import HomePage from "../pages/HomePage";
import AdminDashboardPage from "../pages/AdminDashboardPage";
import CollectionPage from "../pages/CollectionPage";
import AdminLayout from "../layouts/AdminLayout";
import SignupPage from "../pages/SignupPage";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage";
import ChallengesPage from "../pages/ChallengesPage";
import LeaderboardChallengeList from "../pages/leaderboards/LeaderboardChallengeList";
import TransactionsPage from "../pages/TransactionsPage";
import PrivateRoute from "./privateRoute";
import AccountLayout from "../layouts/AccountLayout";
import OnboardLayout from "../layouts/OnboardLayout";
import EmailVerificationPage from "../pages/email/EmailVerificationPage";
import EmailVerificationStatusPage from "../pages/email/EmailVerificationStatusPage";
import SavingPlansPage from "../pages/SavingPlansPage";
import BankPage from "../pages/BankPage";
import LeaderboardPage from "../pages/LeaderboardPage";
import AdminMainDashboardPage from "../pages/AdminMainDashboardPage";
import ReferralPage from "../pages/ReferralPage";
import ReferralEarningsPage from "../pages/ReferralEarningsPage";
import WithdrawalAccountPage from "../pages/WithdrawalAccountPage";
import PasswordResetPage from "../pages/password-reset/PasswordResetPage";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Layout />}>
        <Route element={<OnboardLayout />}>
        <Route path="/" element={<LoginPage></LoginPage>}></Route>
          <Route path="/onboard" element={<SignupPage></SignupPage>}></Route>
          <Route path="/onboard/:referCode" element={<SignupPage></SignupPage>}></Route>
          <Route path="/login" element={<LoginPage></LoginPage>}></Route>
          <Route path="/verify/:status/:message" element={<EmailVerificationStatusPage></EmailVerificationStatusPage>}></Route>
          <Route path="/email/verify/:emailAddress/:verificationAuthCode" element={<EmailVerificationPage></EmailVerificationPage>}></Route>
          <Route path="/password-reset/:code" element={<PasswordResetPage></PasswordResetPage>}></Route>
          <Route path="/password-reset" element={<PasswordResetPage></PasswordResetPage>}></Route>
        </Route>

      </Route>

      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route element={<AccountLayout />}>
            <Route path="/dashboard" element={<DashboardPage></DashboardPage>}></Route>
            <Route path="/saving-plans" element={<SavingPlansPage></SavingPlansPage>}></Route>
            <Route path="/save/challenges" element={<ChallengesPage></ChallengesPage>}></Route>
            <Route path="/bank/add" element={<WithdrawalAccountPage></WithdrawalAccountPage>}></Route>
            {/*<Route path="/leaderboards" element={<LeaderboardChallengeList></LeaderboardChallengeList>}></Route>*/}
            <Route path="/leaderboards" element={<LeaderboardPage></LeaderboardPage>}></Route>
            <Route path="/transactions" element={<TransactionsPage></TransactionsPage>}></Route>
            <Route path="/referrals" element={<ReferralPage></ReferralPage>}></Route>
            <Route path="/referral/earnings" element={<ReferralEarningsPage></ReferralEarningsPage>}></Route>
            <Route path="/admin/dashboard" element={<AdminMainDashboardPage></AdminMainDashboardPage>}></Route>
            {/*<Route path="/banks" element={<BankPage></BankPage>}></Route>*/}
          </Route>
        </Route>
      </Route>
    </Route >

  )
);


export default homeRouter;

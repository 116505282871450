import { Link } from "react-router-dom";
import InputComponent from "../components/InputComponent";
import YoungImg from "../assets/img/young-green.jpg";
import TreeImg from "../assets/img/treeinglass.jpg"
import { useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useSignIn from "../hooks/useLogin";

let LoginPage = () => {
    let [isLoading, setIsLoading] = useState(false);
    let [password, setPassword] = useState(null);
    let [emailAddress, setEmailAddress] = useState(null);
    let [isRouzoUser, setIsRouzoUser] = useState(false);
    let { signIn } = useSignIn()

    let handleIsRouzoUser = (e) => {
        console.log(e)
        console.log(e.target.checked)
        if (e.target.checked) {
            setIsRouzoUser(true);
        }
        else if (!e.target.checked) {
            setIsRouzoUser(false)
        }
        else {
            setIsRouzoUser(false)
        }
    }
    let handleLogin = async () => {
        setIsLoading(true);


        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress))) {
            console.log("Invalid mail");
            setEmailAddress(emailAddress.toLowerCase())
            setIsLoading(false);
            return toast.error("Invalid email address");
        }
        let signin = await signIn({
            emailAddress: emailAddress,
            password: password,
            isRouzoUser: isRouzoUser
        })
        console.log(signin)
        if (signin.success) {
            toast.success(signin.msg);
            window.location = "/dashboard";
            setIsLoading(false)

        }
        else if (!signin.success) {
            setIsLoading(false);
            return toast.error(signin.msg);
        }
        else if (signin.networkError) {
            setIsLoading(false);
            console.log(signin.errors)
            return toast.error("An error occured");
        }
        /*setTimeout(() => {
            if(password != "oreoluwa"){
                setIsLoading(false);
                return toast.error("Invalid Login details");
            }
            toast.success("Login Successful. Redirecting to dashboard");
            window.location = "/dashboard";
            setIsLoading(false)
        },3000)*/
    }
    return <>
        <div style={{ background: `url(${TreeImg})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className="flex h-screen justify-center flex-col items-center lota-font font-lota">
            <p className="p-2 bg-white rounded-md "><FontAwesomeIcon className="text-rouzo-base-color text-sm" icon={faInfoCircle}></FontAwesomeIcon> For existing Rouzo users you can login with your Rouzo details.</p>
            <br></br>

            <div className="flex flex-col bg-rouzo-base-color shadow-2xl rounded-2xl p-8 pb-0 items-center">

                <div className="flex flex-col gap-4">

                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col w-full text-left">
                            <div className="text-left">
                                <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Email address</span>
                            </div>
                            <InputComponent onChange={(e) => setEmailAddress(e.target.value)} className="w-full" placeholder="Email address" ></InputComponent>
                        </div>


                    </div>
                    <div className="flex flex-col">
                        <div className="text-left">
                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Password</span>
                        </div>
                        <InputComponent onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" ></InputComponent>
                    </div>
                    <div className="flex flex-row gap-2 items-center text-white">
                        <input className="rounded-md hover:cursor-pointer" onChange={(e) => handleIsRouzoUser(e)} type="checkbox"></input>
                        <p className="">Are you a Rouzo user ?</p>
                    </div>
                    <div className="relative text-right left-12 -bottom-2">
                        <div onClick={() => handleLogin()} className="flex flex-row items-center justify-between hover:cursor-pointer hover:underline hover:underline-offset-1 shadow-md button bg-white font-bold text-xl text-rouzo-base-color rounded-tl-md px-4 py-1 font-lota ">
                            <p>Login</p>
                            {isLoading ? <FontAwesomeIcon className="h-4 text-rouzo-green-light" spinPulse={true} icon={faSpinner} spin /> : ""}
                        </div>
                    </div>

                </div>


            </div>
            <div className="flex flex-row gap-2">
                <div className="mt-6 bg-white p-2 rounded-full">
                    <p className="text-xs">New here ? <Link className="underline underline-offset-1" to={"/onboard"}>Signup</Link></p>
                </div>
                <div className="mt-6 bg-white p-2 rounded-full">
                    <p className="text-xs">Forgot your password ? <Link className="underline underline-offset-1" to={"/password-reset"}>Reset it </Link></p>
                </div>
            </div>

        </div>
    </>
}


export default LoginPage;
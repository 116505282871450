import { faArrowCircleLeft, faArrowUpFromBracket, faCheck, faInfoCircle, faMoneyBill, faPause, faPauseCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import WaveImg from "../assets/img/wave.svg"

import NewSavingPlan from "../components/modals/NewSavingPlan";
import { useContext, useEffect, useState } from "react";
import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import SavingPlanTopupComponent from "../components/SavingPlanTopupComponent";
import Constants from "../constants/Constants";

let SavingPlansPage = () => {
    const { fetchProfile, fetchSavingPlans } = useAccounts();
    let { savingPlans } = useContext(AccountContext);
    let [selectedPlan, setSelectedPlan] = useState("");
    let [isTopupModalActive, setTopupModalActive] = useState(false);
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    let [savingPlanId , setSavingPlanId] = useState(null);
    useEffect(() => {
        if (!savingPlans) {
            fetchSavingPlans();
        }
    })

    let handleTopup = async (id) => {
        setSavingPlanId(id);
        setTopupModalActive(true)
        console.log("Setting plan");
    }
    return <>
        {isTopupModalActive ? <SavingPlanTopupComponent savingPlanId={savingPlanId} setIsModalActive={setTopupModalActive}></SavingPlanTopupComponent> : ""}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-8 ">
                <div className="flex flex-row gap-4 border-b-2 items-center p-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Saving Plans</p>
                    </div>
                </div>
                {/*style={{background : `url('${WaveImg}')`, backgroundRepeat: "no-repeat", backgroundPosition : "center", backgroundSize : "contain"}}*/}
                <div className="px-4">
                    <div className="flex flex-col gap-4">
                        {
                            (savingPlans && savingPlans.length > 0) ? savingPlans.map(plan => {
                                return <div className="rounded-xl   w-full">
                                    <div style={{ background: `url('${WaveImg}')`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} className="rounded-xl">
                                        <div className="p-4">
                                        <div className="flex items-center flex-row justify-between ">
                                            <span className="font-bold rounded-xl flex flex-row gap-2 items-center bg-white text-xs py-1 px-2 ">
                                                <span>{plan.savingPlanName} </span>
                                                {plan.status == "active" ? <FontAwesomeIcon className="font-bold text-xs text-rouzo-green-2" icon={faCheck}></FontAwesomeIcon> : ""}
                                                {plan.status == "pending" ? <FontAwesomeIcon className="font-bold text-xs text-rouzo-overlay" icon={faPauseCircle}></FontAwesomeIcon> : ""}
                                            </span>
                                            <span className="bg-white text-xs rounded-md font-bold text-rouzo-deep-blue py-1 px-2">
                                                <p className="text-rouzo-green-light"> {plan.savingChallengeType}</p>
                                                <p>{Constants.nairaString} {plan.amount} every month</p>
                                            </span>
                                        </div>
                                        <div className="flex flex-col text-left items-start ">
                                            <div className="flex flex-col">
                                                <p className="text-xs font-regular m-0">Saving Plan Value</p>
                                                <p className="text-3xl mt-0"> <span className="text-xs">{Constants.nairaString} </span>{plan.value}</p>
                                            </div>

                                        </div>
                                        </div>
                                       
                                        {plan.status == "active" ? <div onClick={() => handleTopup(`${plan.savingPlanId}`)} className="hover:cursor-pointer flex flex-row justify-end">
                                            <span className="bg-rouzo-base-color font-bold rounded-tl-xl py-1 text-white px-4">Topup from Stash</span>
                                        </div>
                                        : ""}
                                        {plan.status == "matured" ? <div className="hover:cursor-pointer flex flex-row justify-end">
                                            <span className="bg-rouzo-base-color font-bold rounded-tl-xl py-1 text-white px-4">MATURED</span>
                                        </div>
                                        : ""}
                                        
                                    </div>

                                </div>
                            }) : <div className="py-8">
                                <div>
                                    <FontAwesomeIcon className="text-3xl text-rouzo-deep-bluee " icon={faArrowUpFromBracket}></FontAwesomeIcon>
                                </div>
                                <p className="rounded-xl px-2 text-rouzo-grey bg-rouzo-deep-blue">You have not created any saving plan.</p>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>

    </>
}


export default SavingPlansPage
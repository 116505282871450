import { Link, useNavigate } from "react-router-dom";
import WaveImg from "../assets/img/wave.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowDown, faArrowUp, faArrowUpFromBracket, faAward, faBuildingColumns, faCog, faMoneyCheckDollar, faPiggyBank, faRankingStar, faSpinner, faUserFriends, faWallet } from "@fortawesome/free-solid-svg-icons";
import { faCircleDown, faCircleUp } from "@fortawesome/free-regular-svg-icons";
import CTAButtonComponent from "../components/CTAButtonComponent";
import { useContext, useEffect, useState } from "react";
import WithdrawalComponent from '../components/modals/WithdrawalComponent';
import FundStashComponent from '../components/modals/FundStashComponent';
import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import { useAuthContext } from "../hooks/useAuth";
import Constants from "../constants/Constants";
//import NotificationComponent from '../../components/NotificationComponent';
let DashboardPage = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    let { fetchProfile, fetchUserBanks, fetchSavingPlans, fetchTransactions, fetchReferrals } = useAccounts();
    let [isWithdrawalModalActive, setWithdrawalModalActive] = useState(false);
    const { fullName, stashBalance,activeSavingsBalance , setActiveSavingsBalance , banks, warnedBanks, setWarnedBanks, miniTransactions, savingPlans } = useContext(AccountContext);
    let [isFundingModalActive, setFundingModalActive] = useState(false);
    let [isSettingsActive, setSettingsActive] = useState(false);

    let toggleSettings = () => {
        if (isSettingsActive) {
            setSettingsActive(false);
        }
        else {
            setSettingsActive(true);
        }
    }

    let handleLogout = () => {
        dispatch({
            type: "LOGOUT"
        });
    }
    //let [warnedBanks, setWarnedBanks] = useState(false);
    useEffect(() => {
        if (!fullName || fullName == "") {
            fetchProfile(true);
            fetchUserBanks(true);
            fetchSavingPlans(true);
            fetchTransactions(true);
            fetchReferrals(true);
        }
       
        console.log(warnedBanks)
        /*if (!banks && !warnedBanks) {

            console.log("No banks")
            setWarnedBanks(true)
            navigate("/banks");
        }
        else if (banks) {
            if (banks.length < 1 && !warnedBanks) {
                console.log("No banks");
                setWarnedBanks(true)
                navigate("/banks");
            }
            else {
                console.log(banks)
                console.log("Banks added")
            }
        }*/
    }, [])
    return <>
        {isWithdrawalModalActive ? <WithdrawalComponent setIsModalActive={setWithdrawalModalActive} ></WithdrawalComponent> : ""}
        {isFundingModalActive ? <FundStashComponent setIsModalActive={setFundingModalActive} ></FundStashComponent> : ""}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-8">
                <div className="w-full flex flex-col justify-items-center drop-shadow-xl bg-rouzo-base-color rounded-b-2xl">
                    <div className=" py-9 px-8" style={{ background: `url('${WaveImg}')` }}>
                        <br>
                        </br>
                        <br></br>
                        <div className="text-center flex flex-col md:justify-between gap-4 md:gap-8 justify-items-center">
                            <div className="flex flex-col" >
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <p className="font-bold text-white">Hi {fullName ? fullName.split(" ")[0] : <FontAwesomeIcon className="text-white text-xs" spin={true} icon={faSpinner}></FontAwesomeIcon>},</p>
                                    </div>
                                    <div onClick={() => toggleSettings()} className="bg-white px-4 py-0 flex flex-row rounded-full">
                                        <div className="flex flex-row gap-1 items-center hover:underline hover:underline-offset-1 hover:text-rouzo-base-color hover:cursor-pointer">
                                            <FontAwesomeIcon className="text-sm " icon={faCog}></FontAwesomeIcon>
                                            <p className="font-bold text-xs">Settings</p>

                                        </div>
                                    </div>
                                    {
                                        isSettingsActive ? <div className="show absolute z-50 right-8 bg-white p-2 rounded-xl top-28 flex flex-col gap-2">
                                            <Link to={"/bank/add"}><p className="rounded-xl px-2 py-0 text-sm font-bold hover:cursor-pointer hover:bg-rouzo-blue-light hover:text-rouzo-base-color">Add Withdrawal Account</p></Link>
                                            <p onClick={() => handleLogout()} className="rounded-xl px-2 py-0 text-sm font-bold hover:cursor-pointer hover:bg-rouzo-blue-light hover:text-rouzo-base-color">Logout</p>
                                        </div>
                                            : ""}
                                </div>

                            </div>
                            <div className="overflow-x-scroll  flex flex-row items-center gap-4 p-2">
                                <div className="text-center  md:min-h-24  flex flex-col md:flex-row md:justify-between gap-4  justify-items-center outline outline-2 outline-rouzo-blue-light p-2 rounded-md drop-shadow-2xl">
                                    <div>
                                        <div className="items-center">
                                            <p className="font-bold text-center md:text-left text-xs mb-0 text-white"><FontAwesomeIcon icon={faWallet}></FontAwesomeIcon> &nbsp;Stash Balance</p>
                                        </div>

                                        <p className="text-white font-bold text-3xl mt-0 pt-0">{Constants.nairaString}  {
                                            (isNaN(stashBalance) == false) ?
                                                stashBalance.toString().split(".")[0]
                                                : <>
                                                    {console.log(stashBalance)}
                                                </>
                                        }.
                                            <span className="text-sm text-black">{stashBalance ? (stashBalance.toString().split(".")[1] ? stashBalance.toString().split(".")[1].padString(2, 0) : 0.0) : 0.0}</span>
                                        </p>
                                    </div>
                                    <div className="flex flex-row md:flex-col justify-center gap-4 md:gap-2 md:justify-end text-xs">

                                        <div onClick={() => setWithdrawalModalActive(true)} className="flex flex-row bg-rouzo-deep-blue py-2 px-5 items-center gap-2 rounded-md text-rouzo-blue-light">
                                            <div>
                                                <FontAwesomeIcon icon={faCircleUp}></FontAwesomeIcon>
                                            </div>
                                            <p>Withdraw</p>
                                        </div>

                                        <div onClick={() => setFundingModalActive(true)} className="flex flex-row py-2 px-5 bg-rouzo-deep-blue  items-center gap-2  rounded-md text-rouzo-blue-light">
                                            <div>
                                                <FontAwesomeIcon icon={faCircleDown}></FontAwesomeIcon>
                                            </div>
                                            <p>Topup</p>
                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <div className="text-center md:min-h-24 grow min-w-max md:w-80 md:flex-row flex flex-col md:justify-between gap-4 md:gap-4 justify-items-center outline outline-2 outline-rouzo-blue-light p-2 rounded-md drop-shadow-2xl">
                                        <div>
                                            <div className="items-center">
                                                <p className="font-bold text-center md:text-left text-xs mb-0 text-white"><FontAwesomeIcon icon={faPiggyBank}></FontAwesomeIcon> &nbsp;Total Savings Balance</p>
                                            </div>

                                            <p className="text-white font-bold text-3xl mt-0 pt-0">{Constants.nairaString} {
                                                (isNaN(activeSavingsBalance) == false) ?
                                                activeSavingsBalance.toString().split(".")[0]
                                                    : <>
                                                        {console.log(activeSavingsBalance)}
                                                    </>
                                            }.
                                                <span className="text-sm text-black">{activeSavingsBalance ? (activeSavingsBalance.toString().split(".")[1] ? activeSavingsBalance.toString().split(".")[1].padString(2, 0) : 0.0) : 0.0}</span>
                                            </p>
                                        </div>
                                        <div className="flex flex-row md:flex-col justify-center gap-4 md:gap-2 md:justify-end text-xs">

                                            <Link to={"/saving-plans"}>
                                                <div className="flex flex-row py-2 md:px-2 px-5 bg-rouzo-deep-blue  items-center gap-2  rounded-md text-rouzo-blue-light">
                                                    <div>
                                                        <FontAwesomeIcon icon={faCircleDown}></FontAwesomeIcon>
                                                    </div>
                                                    <p>Topup Saving Plan</p>
                                                </div>
                                            </Link>
                                            <Link to={"/save/challenges"}>
                                                <div onClick={() => setFundingModalActive(true)} className="flex flex-row py-2 md:px-2 px-5 bg-rouzo-deep-blue  items-center gap-2  rounded-md text-rouzo-blue-light">
                                                    <div>
                                                        <FontAwesomeIcon icon={faCircleDown}></FontAwesomeIcon>
                                                    </div>
                                                    <p>Join Saving Challenge</p>
                                                </div>
                                            </Link>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>


                </div>

                {/*Notifications*/}
                <div className="flex flex-col px-4 md:px-0">
                    <div className="bg-rouzo-grey rounded-xl px-2 py-5">
                        <p className="font-bold text-sm">Join a savings challenge and save yourself</p>
                    </div>
                    <div className="">

                    </div>
                </div>

                {/*Bottom*/}
                <div className="px-2 md:px-0 h-full">
                    <div className=" flex flex-col justify-between  pt-4 border-t-2  border-l-2  border-r-2 rounded-t-2xl h-full   border-t-rouzo-grey border-r-rouzo-grey border-l-rouzo-grey">
                        <div className="flex flex-col gap-4">
                            <div className="p-4 grid grid-cols-3 gap-4 justify-between">
                                <Link to={"/save/challenges"}>
                                    <div className="flex flex-col  gap-2 justify-center">
                                        <div className=" p-4 rounded-xl bg-rouzo-green-light">
                                            <FontAwesomeIcon className="text-2xl" icon={faPiggyBank}></FontAwesomeIcon>
                                        </div>
                                        <p className="text-sm font-bold ">Save</p>
                                    </div>
                                </Link>
                                <Link to={"/leaderboards"}>
                                    <div className="flex flex-col gap-2 justify-center">
                                        <div className="rounded-xl p-4 px-4 py-4 bg-rouzo-orange-default">
                                            <FontAwesomeIcon className="text-2xl" icon={faRankingStar}></FontAwesomeIcon>
                                        </div>
                                        <p className="text-xs font-bold ">Leaderboard</p>
                                    </div>
                                </Link>
                                <Link to={"/save/challenges"}>
                                    <div className="flex flex-col gap-2 justify-center">
                                        <div className="rounded-xl p-4 bg-rouzo-base-color">
                                            <FontAwesomeIcon className="text-2xl" icon={faAward}></FontAwesomeIcon>
                                        </div>
                                        <p className="text-xs font-bold ">Saving Challenge</p>
                                    </div>
                                </Link>
                                {/*<div>
                                    <Link to={"/banks"}>
                                        <div className="flex flex-col gap-2 justify-center">
                                            <div className="rounded-xl p-4 bg-rouzo-deep-blue ">
                                                <FontAwesomeIcon icon={faBuildingColumns} className="text-white text-2xl"></FontAwesomeIcon>
                                            </div>
                                            <p className="text-xs font-bold ">Banks</p>
                                        </div>
                                    </Link>
                                </div>*/}
                                <div>
                                    <Link to={"/saving-plans"}>
                                        <div className="flex flex-col gap-2 justify-center">
                                            <div className="rounded-xl p-4 bg-rouzo-green-2">
                                                <FontAwesomeIcon className="text-2xl" icon={faAward}></FontAwesomeIcon>
                                            </div>
                                            <p className="text-xs font-bold ">Active Saving Plans</p>
                                        </div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/referrals"}>
                                        <div className="flex flex-col gap-2 justify-center">
                                            <div className="rounded-xl p-4 bg-rouzo-base-color">
                                                <FontAwesomeIcon className="text-2xl" icon={faUserFriends}></FontAwesomeIcon>
                                            </div>
                                            <p className="text-xs font-bold ">Referrals</p>
                                        </div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/referral/earnings"}>
                                        <div className="flex flex-col gap-2 justify-center">
                                            <div className="rounded-xl p-4 bg-rouzo-orange-default">
                                                <FontAwesomeIcon className="text-2xl" icon={faMoneyCheckDollar}></FontAwesomeIcon>
                                            </div>
                                            <p className="text-xs font-bold ">Referral Earnings</p>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                            <div className="px-4">
                                <div className="flex flex-row justify-between">
                                    <p className="text-rouzo-deep-blue font-bold text-md">Transactions</p>
                                    <Link to={"/transactions"}><p className="font-bold underline text-xs underline-offset-1 hover:underline-offset-1">view all</p></Link>
                                </div>
                                {/*transactions */}
                                <div className="">
                                    <div className="flex flex-col gap-2 justify-center ">
                                        {
                                            miniTransactions && miniTransactions.length > 0 ?
                                                miniTransactions.map(trans => {
                                                    return <div>{
                                                        (trans.type == "credit") ?
                                                            (<div className="border flex flex-row gap-2 justify-between py-2 rounded-md px-4 border-2 items-center border-green-100 bg-green-50">
                                                                <div className="justify-start flex flex-row items-center gap-4">
                                                                    <FontAwesomeIcon className="text-green-400 text-sm" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                                    <p className="font-bold text-md ">NGN{trans.amount}</p>
                                                                </div>
                                                                {/*<p>{trans.amount}</p>*/}
                                                                {trans.status == "pending" ?
                                                                    <div className="rounded-xl rounded-full text-black border-2 border-black bg-rouzo-grey px-4 text-xs  ">
                                                                        pending
                                                                    </div>
                                                                    : ""}
                                                                {trans.status == "failed" ?
                                                                    <div className="rounded-xl rounded-full text-white border-2 border-red-600 bg-red-400 px-4 text-xs  ">
                                                                        failed
                                                                    </div>
                                                                    : ""}
                                                                {trans.status == "success" ?
                                                                    <div className="rounded-xl rounded-full text-white border-2 border-green-600 bg-green-400 px-4 text-xs  ">
                                                                        success
                                                                    </div>
                                                                    : ""}
                                                            </div>)
                                                            : ""
                                                    }
                                                        {
                                                            (trans.type == "debit") ?
                                                                <div className="border flex flex-row gap-2 justify-between px-4 border-orange-100 bg-orange-50">
                                                                    <FontAwesomeIcon className="text-orange-400 text-sm" icon={faArrowDown}></FontAwesomeIcon>
                                                                    <p>{trans.amount}</p>
                                                                    {/*<p>{trans.amount}</p>*/}
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                }) :
                                                <div className="flex flex-col py-8">
                                                    <div>
                                                        <FontAwesomeIcon className="text-3xl text-rouzo-deep-bluee " icon={faArrowUpFromBracket}></FontAwesomeIcon>
                                                    </div>
                                                    <p className="rounded-xl px-2 text-rouzo-grey bg-rouzo-deep-blue">You have not performed any transaction.</p>
                                                </div>
                                        }

                                    </div>
                                    {/*<div className="p-4 flex flex-row rounded-xl border- bg-rose-400">
                                    
                                        <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl justify-around">

                        </div>
                    </div>
                </div>

            </div>


        </div>
    </>
}

export default DashboardPage;
import { useContext, useEffect, useState } from "react";
import InputComponent from "../InputComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import { AnimatePresence, motion } from "framer-motion";
import ModalRootComponent from "./ModalRootComponent";
import Constants from "../../constants/Constants";
import { faCreditCard, faBuildingColumns, faMoneyBillTransfer, faSpinner } from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import SavingsService from "../../api/services/SavingsService";
import { AccountContext } from "../../context/AccountContext";
import useAccounts from "../../hooks/useAccount";
//import { Link } from "react-router-dom";

import { Link, useNavigate } from "react-router-dom";
let NewSavingPlan = ({ setNewSavingPlanModalActive, amount, type }) => {
    let [currentPage, setCurrentPage] = useState(1);
    //let { investor } = useContext(AccountContext);
    // let {fetchProfile} = useAccounts()
    
    const navigate = useNavigate();

    let [savingMethod, setSavingMethod] = useState("");
    let [savingPlanName, setSavingPlanName] = useState("");
    let [savingPlanAmount, setSavingPlanAmount] = useState(0);
    let [savingPlanLength, setSavingPlanLength] = useState("3");
    let [isLoading, setIsLoading] = useState(false);
    //let {banks} = useContext(AccountContext);
    //let [savingPlanFrequency, setSavingPlanFrequency] = useState("daily");
    let [accountId, setAccountId] = useState("");
    let [payMethod, setPayMethod] = useState(1);
    useEffect(() => {
        //console.log(banks)
        if (savingPlanAmount == 0) {
            console.log("setting saving plan amount")
            setSavingPlanAmount(amount);
            
        }
    }, [])


    let page = {
        back: () => {
            if (currentPage == 1) {
                console.log(currentPage);
                console.log("we should go back");
                console.log(setNewSavingPlanModalActive)
                setNewSavingPlanModalActive(false);
            }
            if (currentPage > 1) {
                setCurrentPage(currentPage => currentPage - 1);
            }

        },
        next: () => {
            if (currentPage < 5) {
                setCurrentPage(currentPage => currentPage + 1);
            }
        }
    }
    let handleProceedPayment = async () => {
        console.log(amount)
        setSavingPlanAmount(amount)
        if (!savingPlanAmount) {
            setCurrentPage(2);
            return toast.error("You missed your saving plan amount")
        }

        if (!savingPlanLength) {
            setCurrentPage(3);
            return toast.error("You missed setting up how long you want to save for");
        }
        if (savingPlanAmount && savingPlanLength) {
            page.next();

        }
    }

    let payWithStash = async () => {
        setIsLoading(true);
        if(isLoading){
            return;
        }

        if (savingPlanAmount < 200) {
            toast.error(`Minimum saving amount is ${Constants.nairaString}200`);
            setIsLoading(false);
            return;
        }


        let newSaving = await SavingsService.stash.initiate({
            type: type,
            name: savingPlanName,
            amount: savingPlanAmount,
        })
        console.log("New saving::  ", newSaving);
        if (newSaving) {
            if (newSaving.type == "success") {
                toast.success("Saving Plan created successfully. Redirecting to your plans");
                
                //console.log(url);
                setTimeout(() => {
                    navigate("/saving-plans")
                }, 2000);
            }
            else{
                toast.error(newSaving.msg);
            }
        }
        setIsLoading(false);
    }
    let payWithCard = async () => {
        if(isLoading){
            return;
        }
        setIsLoading(true)

        let newSaving = await SavingsService.card.initiate({
           
            type: type,
            name: savingPlanName,
            amount: savingPlanAmount,
            span: savingPlanLength
        })
        console.log("New saving::  ", newSaving);
        if (newSaving) {
            if (newSaving.success) {
                toast.success("Redirecting to payment gateway shortly to initiate your saving process.");
                let { url } = newSaving.data;
                console.log(url);
                setTimeout(() => {
                    window.location = url;
                }, 3000);
            }
            else{
                toast.error(newSaving.msg);
            }
        }
        setIsLoading(false);
    }
    let payWithBank = async (_accountId) => {
        //console.log(investor);
        /*if(!investor){
            await fetchProfile();
        }*/
        console.log(_accountId)

        setAccountId(_accountId);
        
       

        if (savingPlanAmount < 200) {
            toast.error(`Minimum saving amount is ${Constants.nairaString}200`);
            return;
        }


        let newSaving = await SavingsService.bank.initiate({
            accountId: accountId,
            type: type,
            name: savingPlanName,
            amount: savingPlanAmount,
            span: savingPlanLength
        })
        console.log("New saving::  ", newSaving);
        if (newSaving) {
            if (newSaving.type == "success") {
                toast.success("Redirecting to payment gateway shortly to initiate your saving process.");
                let { url } = newSaving.data;
                console.log(url);
                setTimeout(() => {
                    window.location = url;
                }, 3000);
            }
            else{
                toast.error(newSaving.msg);
            }
        }
    }
    let handleSavingPlanAmount = (e) => {
        setSavingPlanAmount(e.target.value);
    }
    let handleSavingPlanName = (e) => {
        setSavingPlanName(e.target.value);
    }
    let handleSavingPlanLength = (e) => {

        setSavingPlanLength(e.target.value);
        console.log("SavingPlanLength: ", savingPlanLength);
    }
    let handlePageSwitch = () => {
        console.log("page switch");
        console.log(currentPage);
        switch (currentPage) {
            case 1:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-2xl mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">Saving title</p>
                            <InputComponent value={savingPlanName} onChange={(e) => handleSavingPlanName(e)}></InputComponent>
                        </div>
                    </motion.div>


                </div>
            case 2:
                return <div>
                    <p className="text-rouzo-text-color-2 font-bold text-2xl mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-[100px] mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want to save?</p>
                            <InputComponent disabled value={amount} placeholder={"₦ 100000 min : ₦100"} type="number" ></InputComponent>
                        </div>

                    </motion.div>

                </div>
            case 3:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-6 mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want to save?</p>
                            <p disabled >₦{amount}</p>
                        </div>
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How long do you want to save for?</p>
                            <SelectComponent data={Constants.SAVINGS.PLANS_LENGTH} ></SelectComponent>
                        </div>

                    </motion.div>

                </div>;

            case 4:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Pay ₦{savingPlanAmount} using : </p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="flex flex-col space-y-6">
                            <div onClick={() => payWithCard()} className={`flex space-x-[12px] ${isLoading ? "bg-rouzo-grey" : "bg-rouzo-blue-light"}  p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color`}>
                                <FontAwesomeIcon icon={faCreditCard} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Card</p>
                                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin={true} className="text-rouzo-base-color h-4"></FontAwesomeIcon> : ""}
                            </div>
                            {/*<div onClick={() => pay()} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Transfer</p>
                            </div>*/}
                            {/*<div className="flex flex-col border-2 border-rouzo-grey gap-2 rounded-xl p-2">
                                <div onClick={() => payWithBank()} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                    <FontAwesomeIcon icon={faBuildingColumns} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                    <p className="text-sm font-semibold">Bank</p>
                                </div>
                                <div className="flex-row flex overflow-x-scroll">
                                    {
                                        banks ? banks.map(bank => {
                                            return <p onClick={() => payWithBank(`${bank.accountId}`)} className="hover:cursor-pointer rounded-xl text-xs text-rouzo-green-light font-bold bg-rouzo-blue-light p-1 px-2 ">
                                                    {bank.institutionName} - {bank.accountNumber}
                                                </p>
                                        }) : <Link to={"/banks"}><p className="text-center hover:cursor-pointer text-xs font-bold p-1 px-2 text-white rounded-xl bg-rouzo-green-light ">Add a bank</p></Link>
                                    }
                                </div>
                                </div>*/}
                            <div onClick={() => payWithStash()} className={`flex space-x-[12px] ${isLoading ? "bg-rouzo-grey" : "bg-rouzo-blue-light"}  p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-cancel hover:border-rouzo-base-color`}>
                                <p className="text-sm font-semibold">Rouzo Stash</p>
                                {isLoading ? <FontAwesomeIcon  icon={faSpinner} spin={true} className="text-rouzo-base-color h-4"></FontAwesomeIcon> : ""}
                                {/*<p className="text-right text-green-400 text-xs p-1 border border-green-400 rounded-2xl">COMING SOON</p>*/}
                            </div>
                        </div>

                    </motion.div>
                </div>
        }
    }
    return <>
        <ModalRootComponent
            children={
                <div className="z20 font-lota lota-font rounded-2xl self-center p-6 pt-9 bg-white">
                    <div onClick={page.back} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'<'} back</p>
                    </div>
                    <div>
                        {handlePageSwitch()}
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            {
                                currentPage < 3 ?
                                    <CTAButtonComponent onClick={page.next} text={(currentPage < 3) ? "Continue" : "Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }
                            {
                                currentPage == 3 ?
                                    <CTAButtonComponent onClick={handleProceedPayment} text={"Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }



                        </div>

                    </div>
                </div>
            }
        ></ModalRootComponent>
    </>

}


export default NewSavingPlan;
import { faArrowAltCircleDown, faArrowDown, faArrowCircleLeft, faArrowUpFromBracket, faInfoCircle, faMoneyBill, faArrowAltCircleUp, faUserFriends } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

import NewSavingPlan from "../components/modals/NewSavingPlan";
import { useContext, useEffect, useState } from "react";

import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
let ReferralPage = () => {

    const { fetchReferrals } = useAccounts();
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    const { referrals, setReferrals, user } = useContext(AccountContext);
    let [verifedReferrals, setVerifiedReferrals] = useState(null)
    let [unVerifedReferrals, setUnverifiedReferrals] = useState(null)
    useEffect(() => {
        if (!referrals) {
            fetchReferrals()
        }
        if(referrals){
            if(!verifedReferrals) setVerifiedReferrals(referrals.filter(o => o.emailVerified == true));
            if(!unVerifedReferrals) setUnverifiedReferrals(referrals.filter(o => o.emailVerified == false));
        }
    })
    return <>
        {/*{isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive}></NewSavingPlan> : ""}*/}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Referrals</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col justify-items-center gap-2 p-4 drop-shadow-xl bg-rouzo-base-color rounded-b-2xl">
                        <div className="flex flex-col">
                            <p className="text-white font-thin">Total Referrals</p>
                            <p className="text-[100px] text-rouzo-deep-blue font-bold"> {referrals ? referrals.length : 0} </p>
                        </div>
                        <div className="flex flex-row justify-around gap-6 text-white">
                            <div className="flex flex-col gap-2">
                                <p className="text-xl text-rouzo-green-light-2">{verifedReferrals ? verifedReferrals.length : 0}</p>
                                <p className="text-xs font-medium">Verified Referrals</p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-xl text-white">{verifedReferrals ? unVerifedReferrals.length : 0}</p>
                                <p className="text-xs font-medium">Unverified Referrals</p>
                            </div>
                        </div>
                        <div className="bg-white flex flex-row py-1 px-4 rounded-xl">
                            <p className="font-bold text-xs text-rouzo-green-2">https://save.rouzo.co/onboard/{user ? user.referralCode : user.referralCode}</p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {
                            referrals && referrals.length > 0 ?
                                referrals.map(referral => {
                                    return <div>{
                                        
                                            (<div className="border flex flex-row gap-2 justify-between py-2 rounded-md px-4 border-2 items-center border-green-100 bg-green-50">
                                                <div className="justify-start flex flex-row items-center gap-4">
                                                    <FontAwesomeIcon className="text-green-400 text-sm" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                    <p className="font-bold text-md ">{referral.emailAddress}</p>
                                                </div>
                                                {/*<p>{referral.amount}</p>*/}
                                                {referral.emailVerified == false ?
                                                    <div className="rounded-xl rounded-full text-black border-2 border-black bg-rouzo-grey px-4 text-xs  ">
                                                        unverified
                                                    </div>
                                                    : ""}
                                                {referral.emailVerified == true ?
                                                    <div className="rounded-xl rounded-full text-white border-2 border-green-600 bg-green-400 px-4 text-xs  ">
                                                        verified
                                                    </div>
                                                    : ""}
                                            </div>)
                                            
                                    }
                                    </div>
                                }) :
                                <div className="flex flex-col py-8 px-4">
                                    <div>
                                        <FontAwesomeIcon className="text-3xl text-rouzo-deep-bluee " icon={faUserFriends}></FontAwesomeIcon>
                                    </div>
                                    <p className="rounded-xl px-2 text-rouzo-grey bg-rouzo-deep-blue">You have not referred anyone.</p>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>

    </>
}


export default ReferralPage


let SelectComponent = ({data, disabled,selected, onChange}) => {
    console.log(data);
    return <>
        <div className="mulish-font">
            <select onChange={onChange} disabled={disabled ? true : false} className="py-[21px] w-full px-[25px] bg-rouzo-blue-light rounded-xl">
                {data ? data.map(d => {
                    return <option className="mulish-font text-base " value={d.value}>{d.text}</option>
                }) : ""}
            </select>
        </div>
    </>
}

export default SelectComponent;
import { useEffect, useState } from "react";
import AdminService from "../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../components/MiniLoadingComponent";
import Constants from "../constants/Constants";



let AdminMainDashboardPage = () => {

    let [transactions, setTransactions] = useState(null);
    let [users, setUsers] = useState(null);
    let [savingPlans, setSavingPlans] = useState(null);
    let [totalActiveSavingsValue, setTotalActiveSavingsValue] = useState(null);
    let [totalSavingsValue, setTotalSavingsValue] = useState(null);
    let [activeSavingPlans, setActiveSavingPlans] = useState(null);
    let [wallets, setWallets] = useState(null);
    let [transactionsIsLoading, setTransactionsIsLoading] = useState(false);
    let [usersIsLoading, setUsersIsLoading] = useState(false);
    let [savingPlansIsLoading, setSavingPlansIsLoading] = useState(false);


    useEffect(() => {
        if (!users) {
            handleFetchUsers();
        }
        if (!transactions) {
            handleFetchTransactions();
        }
        if (!savingPlans) {
            handleFetchSavingPlans();
        }
    })
    let handleFetchTransactions = async () => {
        setTransactionsIsLoading(true);
        let trans = await AdminService.fetchTransactions();
        if (trans.success) {
            setTransactions(trans.data);
            setTimeout(() => {
                setTransactionsIsLoading(false);
            }, 2000)

        }
    }
    let handleFetchUsers = async () => {
        setUsersIsLoading(true);
        let trans = await AdminService.fetchUsers();
        if (trans.success) {
            setUsers(trans.data);
            setUsersIsLoading(false);
        }
    }
    let handleFetchSavingPlans = async () => {
        setSavingPlansIsLoading(true);
        let trans = await AdminService.fetchSavingPlans();
        if (trans.success) {
            setSavingPlans(trans.data);
            setTotalSavingsValue(trans.data.reduce((a,b) => {return a + b.amount},0));
            setTotalActiveSavingsValue(trans.data.reduce((a,b) => { if(b.status == "active") {return a + b.amount} else return a + 0},0))
            
            console.log(trans.data)
            setSavingPlansIsLoading(false);
        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-screen w-screen p-1">
            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-10 flex flex-col justify-center gap-4">
                    <div className="grid grid-cols-4 border-rouzo-base-color border-b-2">
                        <p className="font-bold text-white text-sm">Home</p>
                        <p className="font-bold text-white text-sm">Users</p>
                        <p className="font-bold text-white text-sm">Transactions</p>
                        <p className="font-bold text-white text-sm">Saving Plans</p>
                    </div>
                    <div className="flex flex-row gap-2 justify-center">
                        <div className="text-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {usersIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{users ? users.length : 0}</p>}
                                <p>Total Users</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {savingPlansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{savingPlans ? savingPlans.length : 0}</p>}
                                <p>Total SavingPlans Created</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {savingPlansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{totalActiveSavingsValue ? `${Constants.nairaString} ${totalActiveSavingsValue}` : `${Constants.nairaString} 0`}</p>}
                                <p>Total SavingPlans Value</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{transactions ? transactions.length : 0}</p>}
                                <p>Total Transactions</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    </>
}


export default AdminMainDashboardPage;
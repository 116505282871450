import { faArrowCircleLeft, faInfoCircle, faMoneyBill } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

import NewSavingPlan from "../components/modals/NewSavingPlan";
import { useState } from "react";

let ChallengesPage = () => {
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    let [challengeName, setChallengeName] = useState(null);
    let [challengeAmount, setChallengeAmount] = useState(null);
    let handleChallengeSelect = (_challengeName,_challengeAmount) => {
        setChallengeAmount(_challengeAmount);
        setChallengeName(_challengeName);
        setNewSavingPlanModalActive(true)
    }
    return <>
    {isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive} amount={challengeAmount} type={challengeName} ></NewSavingPlan> : ""}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-8">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Saving Challenge</p>
                    </div>
                </div>
                <div className="">
                    <p></p>
                    <div className="py-2 px-4 flex flex-col gap-8">
                        {/*<Link to={"/save/challenge/agba-baller"}>*/}
                            <div onClick={() => handleChallengeSelect("agbaballer",500000)} className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Agba Baller</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 500,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>

                        {/*</Link>*/}
                        {/*<Link to={"/save/challenge/odogwu"}>*/}
                            <div onClick={() => handleChallengeSelect("nogreeforanybody",200000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">No Gree For Anybody</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 200,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>
                            <div onClick={() => handleChallengeSelect("odogwu",100000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Odogwu</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 100,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>
                            <div onClick={() => handleChallengeSelect("echoke",50000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">E Choke</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 50,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>
                            <div onClick={() => handleChallengeSelect("profprof",20000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Prof Prof</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 20,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>
                            <div onClick={() => handleChallengeSelect("deyplay",10000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Dey play</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 10,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>

                        {/*</Link>*/}
                        {/*<Link to={"/save/challenge/agba-baller"}>*/}
                            <div onClick={() => handleChallengeSelect("atallatall",5000)}  className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">At all at all</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">
                                    <div className="flex flex-row rounded-full bg-white px-4 items-center">
                                        <FontAwesomeIcon className="text-rouzo-green-light text-sm" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="font-bold text-sm text-rouzo-green-light">NGN 5,000</p>
                                    </div>

                                    <p className="text-xs font-regular text-right ">for 3months</p>
                                </div>

                            </div>

                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </div>
        
    </>
}


export default ChallengesPage
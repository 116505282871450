import { faArrowAltCircleDown, faArrowDown, faArrowCircleLeft, faArrowUpFromBracket, faInfoCircle, faMoneyBill, faArrowAltCircleUp, faUserFriends, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

import NewSavingPlan from "../components/modals/NewSavingPlan";
import { useContext, useEffect, useState } from "react";

import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import Constants from "../constants/Constants";
let ReferralEarningsPage = () => {

    const { fetchReferralEarnings, fetchReferrals } = useAccounts();
    let [totalReferralEarnings, setTotalReferralEarnings] = useState(null);
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    let [verifiedReferrals, setVerifiedReferrals] = useState(null);
    let [unVerifiedReferrals, setUnverifiedReferrals] = useState(null);
    let [isLoadingReferrals, setIsLoadingReferrals] = useState(false);
    let [isLoadingReferralEarnings, setIsLoadingReferralEarnings] = useState(false);
    const { referrals, setReferrals, referralEarnings, setReferralEarnings, user } = useContext(AccountContext);
    useEffect(() => {
        if (!referralEarnings) {

            if (isLoadingReferralEarnings) {
                return;
            }
            setIsLoadingReferralEarnings(true)
            setTimeout(() => {
                fetchReferralEarnings()
            }, 1000)

        }
        if (!referrals) {
            if (isLoadingReferrals) {
                return;
            }
            fetchReferrals()
        }
        if (referrals) {
            setIsLoadingReferrals(false);
            if (!verifiedReferrals) setVerifiedReferrals(referrals.filter(o => o.emailVerified == true));
            if (!unVerifiedReferrals) setUnverifiedReferrals(referrals.filter(o => o.emailVerified == false));
        }
        if (referralEarnings) {
            setIsLoadingReferralEarnings(false)
            setTotalReferralEarnings(referralEarnings.reduce((a, b) => { return a + b.earnedAmount }, 0));
        }
    })
    return <>
        {/*{isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive}></NewSavingPlan> : ""}*/}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Referrals Earnings</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col justify-items-center gap-2 p-4 drop-shadow-xl bg-rouzo-base-color rounded-b-2xl">
                        <div className="flex flex-col">
                            <p className="text-white font-thin">Total Referral Earnings</p>
                            {(isLoadingReferralEarnings == true) ?
                                <FontAwesomeIcon className="h-20 text-rouzo-green-2" spinPulse={true} icon={faSpinner} spin />
                                :
                                <p className="text-[100px] text-rouzo-green-2 font-bold">
                                    {Constants.nairaString}{totalReferralEarnings ? totalReferralEarnings : 0.0}
                                </p>
                            }
                        </div>
                        <div className="flex flex-row justify-around gap-6 text-white">
                            <div className="flex flex-col gap-2">
                                <p className="text-xl text-rouzo-green-light-2">{verifiedReferrals ? verifiedReferrals.length : 0}</p>
                                <p className="text-xs font-medium">Verified Referrals</p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-xl text-white">{verifiedReferrals ? unVerifiedReferrals.length : 0}</p>
                                <p className="text-xs font-medium">Unverified Referrals</p>
                            </div>
                        </div>
                        <div className="bg-white flex flex-row py-1 px-4 rounded-xl">
                            <p className="font-bold text-xs text-rouzo-green-2">https://save.rouzo.co/onboard/{user ? user.referralCode : user.referralCode}</p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">

                        {(isLoadingReferralEarnings == true) ? <FontAwesomeIcon className="h-4 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin />
                            :
                            referralEarnings && referralEarnings.length > 0 ?
                                referralEarnings.map(referral => {
                                    return <div>{

                                        (<div className="border flex flex-row gap-2 justify-between py-2 rounded-md px-4 border-2 items-center border-green-100 bg-green-50">
                                            <div className="justify-start flex flex-row items-center gap-4">
                                                {isLoadingReferralEarnings ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <FontAwesomeIcon className="text-green-400 text-sm" icon={faArrowAltCircleDown}></FontAwesomeIcon>}
                                                <p className="font-bold text-md ">{referral.referralEmailAddress}</p>
                                            </div>
                                            {/*<p>{referral.amount}</p>*/}

                                            <div className="rounded-xl rounded-full text-black border-2 border-black bg-rouzo-grey px-4 text-xs  ">
                                                {Constants.nairaString}{referral.earnedAmount}
                                            </div>

                                        </div>)

                                    }
                                    </div>
                                }) :
                                <div className="flex flex-col py-8 px-4">
                                    <div>
                                        <FontAwesomeIcon className="text-3xl text-rouzo-deep-bluee " icon={faUserFriends}></FontAwesomeIcon>
                                    </div>
                                    <p className="rounded-xl px-2 py-1  justify-center text-rouzo-grey bg-rouzo-deep-blue">Your referrals have not saved yet.   <br></br>Refer your friends and ask them to save in order to earn!!</p>
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div>

    </>
}


export default ReferralEarningsPage
import { useContext, useEffect, useState } from "react";
import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import InputComponent from "../components/InputComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import UserService from "../api/services/UserService";
import SelectComponent3 from "../components/SelectComponent3";
import SelectComponent2 from "../components/SelectComponent2";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";


let WithdrawalAccountPage = () => {
    const navigate = useNavigate();
    let { fetchUserBanks, addBank } = useAccounts();
    let { banks, setBanks } = useContext(AccountContext);
    let [isLoadingAddingAccount, setIsLoadingAddingAccount] = useState(false);
    let [loadedUserBanks, setLoadedUserBanks] = useState(false);
    let [accountNumber, setAccountNumber] = useState(null);
    let [bankCode, setBankCode] = useState(null);
    let [bankName, setBankName] = useState(null);
    let [accountName, setAccountName] = useState(null);

    let [bankList, setBankList] = useState(null);

    let [isAccountResolving, setIsAccountResolving] = useState(false);
    let fetchBankList = async () => {
        let b = await UserService.fetchBankList();

        console.log(b);

        setBankList(b.data);
    }

    let handleChangeAccountNumber = async (e) => {
        console.log("account number changed", e)
        setAccountNumber(e.target.value);
        setAccountName(null);
        // resolveAccount()
    }

    let handleChangeBank = async (e) => {
        console.log("bank changed")
        setAccountName(null);
        setBankCode(e);
        let bName = (bankList.filter(o => o.code == e))[0].name;
        console.log(bName);
        
        setBankName(bName)
        //resolveAccount();
    }

    let handleAddAccount = async () => {
        console.log("click add")
        if (isLoadingAddingAccount) {
            return

        }

        if (accountName) {
            setIsLoadingAddingAccount(true);


            let add = await addBank(accountNumber, bankCode, bankName);
        }
        else {
            toast.error("Account not resolved.")
        }



        setIsLoadingAddingAccount(false);
    }
    useEffect(() => {

        if (!loadedUserBanks) {
            fetchUserBanks();
            setLoadedUserBanks(true);
        }
        if (banks) {
            if (banks.length > 0) {
                toast.success("Withdrawal account exist")
                navigate("/dashboard")
            }
        }
        else {
            console.log("User does not have an account")
        }
        if (bankCode) {
            console.log("BankCode : ", bankCode);
            if (accountNumber) {
                if (accountNumber.length >= 10) {
                    if (accountName) {
                        return
                    }
                    if (isAccountResolving) {
                        return
                    }
                    setIsAccountResolving(true);
                    let res = async () => {


                        let resolve = await UserService.resolveAccount(accountNumber, bankCode, bankName);
                        console.log(resolve);

                        if (resolve.success) {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            setAccountName(resolve.data.account_name);
                            setIsAccountResolving(false)
                        }
                        else {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            toast.error(resolve.msg);
                            setIsAccountResolving(false)
                        }
                    }

                    res()
                }

            }
            else {
                console.log("noin")
            }
        }
        if (!bankList) {
            fetchBankList()
        }



    })
    return <>
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-4">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Add Withdrawal Account</p>
                    </div>
                </div>
                <div className="justify-center flex flex-col gap-4 px-6 py-2">
                    <div className="text-left">
                        <p>Account Number</p>
                        <InputComponent onChange={handleChangeAccountNumber}></InputComponent>
                    </div>
                    <div className="text-left">
                        <p>Bank</p>
                        {bankList ? <SelectComponent3 className="text-black" data={bankList} onChange={(e) => handleChangeBank(e.target.value)}></SelectComponent3>
                            :
                            <SelectComponent2 data={["No banks"]} ></SelectComponent2>}

                    </div>
                    <div className="text-left">
                        <p>Account Name</p>
                        {
                            isAccountResolving ?
                                <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-center text-black">
                                    <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-rouzo-base-color">
                                    </FontAwesomeIcon>
                                </p>
                                :
                                <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-black">{accountName ? accountName : ""} </p>
                        }
                    </div>
                    <p onClick={() => handleAddAccount()} className={`px-10 py-2 ${!isLoadingAddingAccount ? "bg-rouzo-deep-blue hover:cursor-pointer" : "bg-rouzo-blue-light hover:cursor-disabled"} text-white rounded-xl text-md justify-center text-center flex flex-row gap-4 `}>
                        Add Account
                        {isLoadingAddingAccount ?
                            <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-white">
                            </FontAwesomeIcon>
                            : ""}
                    </p>
                </div>
            </div>
        </div>
    </>
}


export default WithdrawalAccountPage;

import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";

let UserService = {
    fetchProfile : async (auth) => {
        let url = Constants.USER.PROFILE;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${auth}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    },
    resolveAccount : async (accountNumber, bankCode, bankName) => {
        let url = "/misc/resolveAccount";
        let req = await axios.post(url, {
            accountNumber : accountNumber,
            bankCode : bankCode,
            bankName : bankName
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    },
    fetchUserBanks : async (auth) => {
        let url = Constants.USER.BANKS;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    },
    addBank : async (accountNumber,bankCode, bankName) => {
        let url = Constants.USER.BANKS;
        let req = await axios.post(url, {
            accountNumber : accountNumber,
            bankCode : bankCode,
            bankName : bankName
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    },
    fetchBankList : async (code,auth) => {
        let url = "/misc/banks";
        let req = await axios.get(url, {
            code : code
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    }
}

export default UserService
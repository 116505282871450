import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../context/AccountContext";
import UserService from "../api/services/UserService";
import { toast } from "react-toastify";
import AccountService from "../api/services/AccountService";
import TransactionService from "../api/services/TransactionService";
import SavingsService from "../api/services/SavingsService";

const useAccounts = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);

    const [error, setError] = useState(null);

    const { userId, setUserId } = useContext(AccountContext);
    const { user , setUser } = useContext(AccountContext);
    const { fullName , setFullName } = useContext(AccountContext);
    const { investmentHistory, setInvestmentHistory } = useContext(AccountContext);
    const { stashBalance, setStashBalance } = useContext(AccountContext);
    const { savingsBalance, setSavingsBalance } = useContext(AccountContext);
    const { transactions, setTransactions } = useContext(AccountContext);
    const { transfers, setTransfers } = useContext(AccountContext);
    const {setMiniTransactions} = useContext(AccountContext);
    const {wallet, setWallet} = useContext(AccountContext);
    const { savings, setSavings } = useContext(AccountContext);
    const { savingPlans, setSavingPlans } = useContext(AccountContext);
    const { isStash, setIsStash } = useContext(AccountContext);
    const {banks, setBanks} = useContext(AccountContext)
    const {referralSlug , setReferralSlug} = useContext(AccountContext);
    const { rankings , setRankings} = useContext(AccountContext);
    const {firstDayRank, setFirstDayRank} = useContext(AccountContext);
    const {lastDayRank, setLastDayRank} = useContext(AccountContext);
    const {yourPosition, setYourPosition} = useContext(AccountContext);
    const {referrals, setReferrals} = useContext(AccountContext);
    const {referralEarnings, setReferralEarnings} = useContext(AccountContext);
    const {activeSavingsBalance , setActiveSavingsBalance} = useContext(AccountContext);
    const token = localStorage.getItem("rouzo_save_token");
    //should for fetch

    const fetchReferrals = async (notify) => {
        try {
            let req = await AccountService.fetchReferrals();

            if(req.success){
                setReferrals(req.data);
                if(!notify) toast.success("Referrals fetched successfully");
            }
            else {
                toast.error("Error fetching referrals");
            }
        }
        catch(e){
            //console.log(e)
            toast.error("Error fetching referrals");
        }
    }
    const fetchReferralEarnings = async (notify) => {
        try {
            let req = await AccountService.fetchReferralEarnings();

            if(req.success){
                setReferralEarnings(req.data);
                //toast.success("Referral earnings fetched successfully");
            }
            else {
                toast.error("Error fetching referral earnings");
            }
        }
        catch(e){
            //console.log(e)
            toast.error("Error fetching referral earnings");
        }
    }

    const fetchRankings = async (challengeName) => {
        try {
            let req = await AccountService.fetchRankings(challengeName);

            if(req.success){
                let rankArray = [];
                Object.keys(req.data.ranking).forEach(r => rankArray.push(req.data.ranking[r]));
                rankArray = rankArray.sort((a,b) => a.amount - b.amount);
                setRankings(rankArray);
                //console.log(rankArray)

                let _userId = userId ? userId : req.data.userId
                let userPositionIndex = rankArray.findIndex((o)=> o.userId == _userId);
                //console.log(userPositionIndex) 
                let userPosition = (userPositionIndex != -1) ? rankArray.length - Number(userPositionIndex)  : null;
                //console.log(_userId);
                
                let yp = req.data.ranking[_userId];
                //console.log(yp);
                if(yp) yp.position = userPosition ;
                console.log("Userposition: ",userPosition)
                setYourPosition(yp);
                //console.log(rankings);
                setFirstDayRank(req.data.firstDay);
                setLastDayRank(req.data.lastDay);
                toast.success("Leaderboard ranking fetched successfully");
                req.rankArray = rankArray;
                return req;
            }
            else{
                toast.error("Error fetching leaderboard rankings");
                return req;
            }
        }
        catch(e){
            //console.log(e);
            toast.error("Error fetching leaderboard rankings");
            return e;
        }
    }

    const fetchSavingPlans = async (notify) => {
        try {
            const res= await SavingsService.fetchAllSavings(token);
            const { type, errors, success } = res;
            //console.log(res.data)
            if(success){
                let resortedPlans = res.data.reverse();
                console.log(resortedPlans)
                setSavingPlans(resortedPlans)
                setSavings(resortedPlans)
                let activePlans = res.data.filter(o => o.status == "active");
                let activePlansBalance = activePlans.reduce((a,b) => {
                    return a + b.value
                }, 0);

                //console.log(activePlans)
                //console.log(activePlansBalance);
                setActiveSavingsBalance(activePlansBalance)
            }
        }
        catch(e) {
            //console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchUserBanks = async (notify) => {
        try {
            const res= await UserService.fetchUserBanks(token);
            const { type, errors, success } = res;
            //console.log(res.data)
            if(success){
                setBanks(res.data)
            }
        }
        catch(e) {
            //console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const addBank = async (accountNumber , bankCode, bankName) => {
        try {
            const res= await UserService.addBank(accountNumber,bankCode, bankName);
            const { type, errors, success } = res;
            //console.log(res);
            if(success){
                toast.success("Successfully added your bank")
                fetchUserBanks()
            }
            else{
                toast.error(`Error adding bank \n ${res.msg}`)
            }
            return res
        }
        catch(e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
               // console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const fetchProfile = async (notify) => {
        try {
            const res = await UserService.fetchProfile(token);
            const { type, errors, success } = res;
            if (success) {
                let { data } = res;
                console.log(res.data);
                setUser(data);
                setUserId(data.userId)
                setFullName(data.fullName);
                setWallet(data.wallet);
                setStashBalance(data.wallet.balance.stash);
                setSavingsBalance(data.wallet.balance.savings);
                setTransactions(data.transactions);
                setTransfers(data.transfers);
                setSavings(data.savings);
                setBanks(data.banks)
                //setPurchase(data.purchase);
                setUserId(data.userId)
                //setInvestmentHistory(data.investments)
               setMiniTransactions(data.fewTransactions)
                setReferralSlug(data.referralSlug);
                
                //let _activeInvestmentBalance = data.activeInvestments.reduce((a,b)=> {return a + Number(b.amount)}, 0);
                //console.log(_activeInvestmentBalance);
                //setActiveInvestmentBalance(_activeInvestmentBalance);
            }
            if (!success) {
                let { statusCode, msg } = res;
                if (statusCode === 205) {
                    //##TODO ALERT
                    //navigate("/onboard");
                }
                else {
                    console.log(msg);
                    alert(msg);
                }
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            //console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                //console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchTransactions = async (notify) => {
        try {
            const res= await TransactionService.fetchTransactions();
            const { type, errors, success } = res;
            //console.log(res);
            if(success){
                if(!notify) toast.success("Successfully fetched your transactions.")
                setTransactions(res.data.reverse())
            }
            else{
                toast.error("Error fetching transactions")
            }
        }
        catch(e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                //console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const updateAccount = async (data) => {
        try {
            const res = await AccountService.updateAccount(data);
            const { type, errors, status } = res;
            if (status === "success") {
                let { data } = res;
                //console.log(res.data.investor);
                fetchProfile()
                toast.success(res.message ? res.message : res.mg);
            }
            if (status === "error") {
                let { statusCode, msg, message } = res;


               // console.log(msg);
                toast.error(msg ? msg : message);

            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        }
        catch (e) {
            //console.log(e);

            if (e.response && e.response.status == 401) {
                toast.error("Session expired. Please do login");
                return setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            return toast.error(e.message ? e.message : e)
        }
    }
    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        fetchProfile,
        updateAccount,
        fetchSavingPlans,
        fetchTransactions,
        addBank,
        fetchUserBanks,
        fetchRankings,
        fetchReferrals,
        fetchReferralEarnings,
        referralEarnings,setReferralEarnings,
        referrals,setReferrals,
        rankings,setRankings,
        firstDayRank,setFirstDayRank,
        lastDayRank, setLastDayRank,
        banks,setBanks,
        user,setUser,
        fullName,setFullName,
        stashBalance, setStashBalance,
        savingsBalance, setSavingsBalance,
        transactions, setTransactions,
        transfers, setTransfers,
        savings, setSavings,
        isStash, setIsStash,
        referralSlug , setReferralSlug,
        wallet, setWallet,
        activeSavingsBalance , setActiveSavingsBalance
        //banks
    };
};

export default useAccounts;

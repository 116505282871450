
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { useNavigate } from "react-router-dom";

let WithdrawalComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();
    let { fetchUserBanks, addBank } = useAccounts();
    let { banks, setBanks } = useContext(AccountContext);

    let { stashBalance } = 0 // useContext(AccountContext);
    let [amount, setAmount] = useState(0);
    let [errorMsg , setErrorMsg] = useState("");

    useEffect(() => {
        if (!banks) {
            toast.error("Withdrawal account does not exist.")
            navigate("/bank/add")
        }
        
    })
    //let {investor, fetchProfile} = useContext(AccountContext);
    let handleWithdraw = async () => {
        if(!amount ){
            return toast.error("Invalid amount.");
        }
        if(amount < 500){
            return toast.error("minimum withdrawal amount is ₦1000");
        }
        
        let funding = await TransactionService.withdraw({
            amount : amount
        })

        if(funding.type == "success"){
            toast.success("Your withdrawal request has been sent. You will be notified as soon as it is approved.");
        }
        else if(funding.type == "error"){
            if(!funding.msg){
                toast.error("There seems to be problem somewhere. No worries we are on top of it.")
            }
            else toast.error(funding.msg);
        }
        console.log(funding);
    }
    let handleAmount = (e) => {
        setAmount(e.target.value);

        if(amount > 5000){
            setErrorMsg("Come on!, you are leaving with all that?");
        }
        else{
            setErrorMsg("");
        }
    }
    return <ModalRootComponent
            children={
                <div className="z20 font-lota lota-font rounded-2xl self-center p-6 pt-9 bg-white">
                    <div onClick={() => setIsModalActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'x'} close</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-rouzo-text-color-2 font-bold text-2xl mb-6">Withdraw funds</p>
                        <div>
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want withdraw?</p>
                            <InputComponent onChange={(e) => handleAmount(e)} type="number" placeholder="₦ 100000"></InputComponent>
                        </div>
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            <CTAButtonComponent onClick={() => handleWithdraw()} text={"Withdraw"}></CTAButtonComponent>
                            
                        </div>
                    </div>
                </div>
            }
        >

        </ModalRootComponent>
}


export default WithdrawalComponent;
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCancel, faInfoCircle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/img/logo.svg"

let EmailVerificationStatusPage = () => {

    let { status, message,emailAddress } = useParams();

    return <>
        {status == "success" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">
                <div className="flex flex-col bg-rouzo-overlay shadow-2xl rounded-2xl p-2  items-center">
                    <div className="flex flex-row justify-between  text-right">
                        <p className="text-xs text-rouzo-base-color">Rouzo Save</p>
                    </div>
                    <div className="flex flex-col p-8  items-center">

                        <FontAwesomeIcon className="text-rouzo-green-light text-[80px]" icon={faThumbsUp}></FontAwesomeIcon>
                        <br></br>
                        <p className="text-rouzo-green-light font-bold text-xl">{message}</p>
                        <br>
                        </br>
                        <Link to={"/login"}>
                            <p className="text-xs font-bold text-rouzo-base-color  underline underline-offset-0">Proceed to login page</p>
                        </Link>
                    </div>


                </div>
            </div>
        </div> : ""}
        {status == "failed" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">
                <div className="flex flex-col bg-rouzo-overlay shadow-2xl rounded-2xl p-8  items-center">
                    <img className="h-24" src={Logo}></img>
                    <FontAwesomeIcon className="text-[80px] text-red-400" icon={faCancel}></FontAwesomeIcon>
                    <p className="text-red-400">Oops!! Email Verification Failed</p>
                    <br>
                    </br>
                    <p  className="hover:cursor-pointer text-underline underline underline-offset-1 text-rouzo-base-color text-sm font-bold">Resend Verification Link</p>
                </div>
            </div>
        </div> : ""}

        {status == "pending" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">
                <div className="flex flex-col bg-rouzo-overlay shadow-2xl rounded-2xl p-8  items-center">
                    <img className="h-24" src={Logo}></img>

                    <p className=""><FontAwesomeIcon className="text-rouzo-base-color text-sm" icon={faInfoCircle}></FontAwesomeIcon>Kindly check your mail for your verification link.</p>

                </div>
            </div>
        </div> : ""}
    </>
}

export default EmailVerificationStatusPage;
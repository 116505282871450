import { useState } from "react";
import CTAButtonComponent from "./CTAButtonComponent";
import InputComponent from "./InputComponent";
import ModalRootComponent from "./modals/ModalRootComponent";
import { toast } from "react-toastify";
import SavingsService from "../api/services/SavingsService";


let SavingPlanTopupComponent = ({ setIsModalActive, savingPlanId }) => {

    let [amount, setAmount] = useState(0);
    let [isLoading, setIsLoading] = useState(false);
    let handleAmount = (e) => {
        setAmount(e.target.value);
    }
    let handleSavingPlanTopup = async () => {
        if (isLoading) {
            return;
        }
        console.log(amount)
        setIsLoading(true)
        if (amount < 200) {
            setIsLoading(false);
            return toast.error("Input an amount equal to or greater than 200");
            return;
        }
        //let topup = {};
        console.log(savingPlanId);
        try {
            let topup = await SavingsService.topupSavings({
                savingPlanId: savingPlanId,
                amount: amount
            })

            if (topup.success) {
                setIsLoading(false);
                toast.success(topup.msg);
                setIsModalActive(false);
                
            }
            else if (!topup.success) {
                toast.error(topup.msg)
                setIsLoading(false);
            }
        }
        catch (e) {
            console.log(e);
            setIsLoading(false);
            toast.error(e)
        }
        


    }
    return <>
        <ModalRootComponent
            children={
                <div className="z20 font-lota lota-font rounded-2xl self-center p-6 pt-9 bg-white">
                    <div onClick={() => setIsModalActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'x'} close</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-rouzo-text-color-2 font-bold text-2xl mb-6">Topup Saving Plan </p>
                        <div>
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">Amount (₦) </p>
                            <InputComponent onChange={(e) => handleAmount(e)} type="number" placeholder="₦ 100000"></InputComponent>
                        </div>
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            <CTAButtonComponent isLoading={isLoading} onClick={() => handleSavingPlanTopup()} text={"Proceed to Topup"}></CTAButtonComponent>

                        </div>
                    </div>
                </div>
            }
        >

        </ModalRootComponent>
    </>
}

export default SavingPlanTopupComponent;
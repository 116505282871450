import { Link, useParams } from "react-router-dom";
import InputComponent from "../components/InputComponent";
import YoungImg from "../assets/img/young-green.jpg";
import TreeImg from "../assets/img/treeinglass.jpg"
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { OnboardContext } from "../context/OnboardContext";
import useOnboard from "../hooks/useOnboard";

let SignupPage = () => {

    const { onboard } = useOnboard();
    let { referCode } = useParams();
    let { fullName, setFullName } = useContext(OnboardContext);
    let { password, setPassword } = useContext(OnboardContext);
    let [confirmPassword, setConfirmPassword] = useState("");
    //let { address, setAddress } = useContext(OnboardContext);
    //let { investorType, setInvestorType } = useContext(OnboardContext);
    let { mobileNumber, setMobileNumber } = useContext(OnboardContext);
    let { emailAddress, setEmailAddress } = useContext(OnboardContext);
    let { referralCode, setReferralCode } = useContext(OnboardContext);
    if (referCode) {
        setReferralCode(referCode);
    }
    let createAccount = () => {
        if (!fullName || !password || !emailAddress) {
            toast.error("You are missing required details");
            //setStage(1);
            return;
        }
        else if (!password) {
            toast.error("Check the password field");
            //setStage(2);
            return;

        }

        /*if (password != confirmPassword) {
            toast.error("Passwords did not match. Try again");
            //setStage(2);
            return;
        }*/

        let data = {
            fullName: fullName,
            password: password,
            mobileNumber: mobileNumber,
            emailAddress: emailAddress,
            password_confirmation: confirmPassword,
            referralCode: referralCode
        }
        console.log(data);
        onboard(data);
    }


    let [isLoading, setIsLoading] = useState(false);
    let handleSignup = () => {

        if (!fullName || fullName == "") {
            setIsLoading(false);
            return toast.error("Invalid full name")
        }
        if (!emailAddress) {
            setIsLoading(false);
            return toast.error("Invalid email address");
        }
        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress))) {
            setIsLoading(false);
            return toast.error("Invalid email address");
        }
        setIsLoading(true);
        setTimeout(() => {
            createAccount()
            setIsLoading(false)
        }, 3000)
    }
    return <>
        <div style={{ background: `url(${YoungImg})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className="flex h-screen justify-center flex-col items-center lota-font font-lota">
            <div className="flex flex-col bg-rouzo-base-color shadow-2xl rounded-2xl p-8 pb-0 items-center">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col">
                            <div className="text-left">
                                <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Full name</span>
                            </div>
                            <InputComponent onChange={(e) => setFullName(e.target.value)} placeholder="Full name"></InputComponent>

                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col w-full text-left">
                            <div className="text-left">
                                <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Email address</span>
                            </div>
                            <InputComponent type="email" name="emailAddress" onChange={(e) => setEmailAddress(e.target.value)} className="w-full" placeholder="Email address" ></InputComponent>
                        </div>


                    </div>
                    <div className="flex flex-col">
                        <div className="text-left">
                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Mobile number</span>
                        </div>
                        <InputComponent type="tel" name="mobileNumber" onChange={(e) => setMobileNumber(e.target.value)} placeholder="Mobile number" ></InputComponent>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-left">
                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Password</span>
                        </div>
                        <InputComponent onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" ></InputComponent>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-left">
                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Referral Code(optional)</span>
                        </div>
                        <InputComponent onChange={(e) => setReferralCode(e.target.value)} value={referCode} type="text" placeholder="Referral Code" ></InputComponent>
                    </div>
                    <div className="relative text-right left-12 -bottom-2">
                        <div onClick={() => handleSignup()} className="flex flex-row items-center gap-2 hover:cursor-pointer hover:underline hover:underline-offset-1 shadow-md button bg-white font-bold text-xl text-rouzo-base-color rounded-tl-md px-4 py-1 font-lota ">
                            <p>Signup</p>
                            {isLoading ? <FontAwesomeIcon className="h-4 text-rouzo-green-light" spinPulse={true} icon={faSpinner} spin /> : ""}
                        </div>
                    </div>

                </div>


            </div>
            <div className="mt-6 bg-white p-2 rounded-full">
                <p className="text-xs">Already have an account ? <Link className="underline underline-offset-1" to={"/login"}>Login</Link></p>
            </div>
        </div>
    </>
}


export default SignupPage;

let Constants = {
    ADMIN : {
        FETCH_TRANSACTIONS : "/admin/transactions",
        FETCH_USERS : "/admin/users",
        FETCH_SAVINGPLANS : "/admin/saving/plans",
    },
    ONBOARD: {
        INITIATE: "/v1/onboard",
        CONFIRM_OTP: {
            EMAIL_ADDRESS: "/v1/auth/onboard/confirmOtp/email",
            MOBILE_NUMBER: "/v1/auth/onboard/confirmOtp/mobileNumber"
        }
    },
    MONO_KEY : "live_pk_z1h059s0lwp9zsj623na",//"test_pk_de183yil1g6bzgnm4nzn",
    LOGIN: {
        INITIATE: "/auth/login",
        CONFIRM_OTP: {
            EMAIL_ADDRESS: "/v1/auth/login/confirmOtp/email",
            MOBILE_NUMBER: "/v1/auth/login/confirmOtp/mobileNumber"
        }
    },
    PASSWORD_RESET : {
        GENERATE : "/auth/password/reset/initiate",
        VERIFY : (code) =>  `/auth/password/reset/verify/${code}`,
        RESET : "/auth/password/reset"
    },
    SIGNUP : "/auth/signup",
    VERIFY_EMAILADDRESS : (emailAddress,verificationAuthCode) => `/auth/code/verify/email/${emailAddress}/authCode/${verificationAuthCode}`,
    USER: {
        PROFILE: "/user/profile",
        BANKS : "/withdrawal/bank-details",
        ADD_BANKS : "",
        REFERRALS : "/user/referrals",
        REFERRAL_EARNINGS : "/user/referral/earnings"
    },
    PAY : {
        INITIATE : "/payment/initialize",
        VERIFY : (reference) => `/payment/verify/${reference}`,
        INITIATE_COLLECTION : (pageSlugId) => {
            if(!pageSlugId) throw "Page Slug Id not used";
            return `/payment/initialize/collection/${pageSlugId}`
        }
    },
    PAGE : {
        CREATE : "/collection-payment-page",
        ONE : (pageId) => `/collection-payment-page/${pageId}`,
        ALL : `/collection-payment-pages`,
        UPDATE : (pageId) => `/collection-payment-page/${pageId}`,
        DELETE : (pageId) => `/collection-payment-page/${pageId}`,
        TRANSACTIONS : {
            ALL : (pageSlugId) => `/transactions/${pageSlugId}`,
            ONE : (pageSlugId, transactionId) => `/transaction/${transactionId}`
        }
    },
    SAVINGS: {
        ONE: "/v1/savings/one",
        ALL: "/savings",
        NEW: "/v1/savings/save/new",
        BANK : {
            INITIATE : "/savings/bank"
        },

        CARD : {
            INITIATE : "/savings/card"
        },
        STASH : {
            INITIATE : "/savings/stash",
            TOPUP : "/savings/stash/topup"
        },
        PLANS_LENGTH: [
            {
                value: "3",
                text: "3 months (3% interest)"
            }
        ],
        PLANS_INTERVAL: [
            {
                value: "daily",
                text: "Daily"
            },
            {
                value: "weekly",
                text: "Weekly"
            },
            {
                value: "monthly",
                text: "Monthly"
            }

        ]
    },
    INVESTMENT: {
        ONE: "/v1/investments/one",
        NEW: "/v1/investments/create",
        TRANSFER: "/v1/investments/transfer",
        UPDATE: "/v1/investments/update",
    },
    TRANSACTIONS: {
        FUND: "/stash/topup/initiate",
        WITHDRAW_INITIATE: "/withdrawal/request",
        FETCH : "/user/transactions"
    },
    ACCOUNTS: {
        UPDATE: "/v1/account/edit",
        BANK_UPDATE_DETAILS: "/v1/account/updateDetails",
        BANK_CREATE_DETAILS: "/v1/account/createDetails",
        FETCH_LEADERBOARD : (challengeName) => `/leaderboard/current/${challengeName}`
    },
    INVESTOR_TYPES: [
        {
            id: "1",
            name: "Beginner",
            icon: 1,
        },
        {
            id: "2",
            name: "Intermediate",
            icon: 2
        },
        {
            id: "3",
            name: "Experienced",
            icon: 3
        }
    ],
    nairaString: "₦",
    LANDING_PAGE: [
        {
            heroText: "Small steps, Big change.",
            subText: `So much of our impact on the Earth is determined by how we spend and make our money. For example, when we purchase a generator set from an electronic retail brand, our money supports the manufacturing of plastic-based metals, the use of fossil fuel energy, excess air waste, and in most cases, unfair labor practices.
            You can reverse this when you put your money into sustainable practices that supports organic food production, renewable energy, zero-plastic manufacturing, and fair labor practices. These are business practices that leave the Earth better than we found it.`,
        },
        {
            heroText: "Contribute",
            subText: `Put your money where your values are. Make a personal impact on the planet by
            saving into green portfolios for competitive returns and sustainable wealth creation.`
        },

        {
            heroText: "Split",
            subText: `Break payments into smaller bits at lower rates when you purchase
            Solar energy assets or energy saving appliances from our partners.`
        },

        {
            heroText: "Rewards",
            subText: `Earn green points when you invite your network, friends and family
            to save on Rouzo and join the efforts of building a greener planet.`
        },

        {
            heroText: "",
            subText: ""
        }
    ]

}

export default Constants;
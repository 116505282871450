import { Link, useParams } from "react-router-dom";
import TreeImg from "../../assets/img/treeinglass.jpg"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../components/InputComponent";
import AuthService from "../../api/services/AuthService";
import TinyErrorComponent from "../../components/TinyErrorComponent";

let PasswordResetPage = () => {
    let { code } = useParams();

    let [emailAddress, setEmailAddress] = useState(null);
    let [password, setPassword] = useState(null);
    let [confirmPassword, setConfirmPassword] = useState(null);
    let [passwordError, setPasswordError] = useState(false);
    let [confirmPasswordError, setConfirmPasswordError] = useState(false);
    let [confirmPasswordError2, setConfirmPasswordError2] = useState(false);
    let [codeVerified, setCodeVerified] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    let handleInitiateReset = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        if (emailAddress) {
            let init = await AuthService.passwordReset.initiate(emailAddress);
            if (init.success) {
                toast.success(init.msg);
            }
            else if (!init.success) {
                toast.error(init.msg);
            }
            else {
                toast.error("An error occured")
            }
            setIsLoading(false);
        }
        else {
            toast.error("Email address not specified");
            setIsLoading(false);
        }


    }

    let handleVerifyCode = async () => {

        if (code) {
            if (codeVerified == true) {
                return;
            }
            if (isLoading) {
                return;
            }
            setIsLoading(true);
            setTimeout(async () => {

                let init = await AuthService.passwordReset.verify(code);
                if (init.success) {
                    setCodeVerified(true);
                }
                else if (!init.success) {
                    toast.error(init.msg + "\n Redirecting..");
                    setCodeVerified(true);
                    setTimeout(() => {
                        window.location = "/password-reset";
                    }, 2000)

                }
                else {
                    toast.error("An error occured")
                }
                setIsLoading(false);
            }, 3000)

        }
        else {
            toast.error("Invalid code. \n Redirecting..");
            setTimeout(() => {
                window.location = "/password-reset"
            }, 2000)
        }
    }

    let handlePasswordReset = async () => {


        if (code) {
            if (codeVerified == false) {
                return;
            }
            if (isLoading) {
                return;
            }
            let check = await handlePasswordChecks();
            console.log(check);
            if (check == true) {
                setIsLoading(true);
                let init = await AuthService.passwordReset.reset({
                    password: password,
                    confirmPassword: confirmPassword,
                    passwordResetCode: code
                });
                if (init.success) {
                    toast.success(init.msg)
                    setTimeout(() => {
                        window.location = "/login";
                    }, 2000)
                }
                else if (!init.success) {
                    toast.error(init.msg);

                }
                else {
                    toast.error("An error occured")
                }
                setIsLoading(false);
            }
            else {
                toast.error("Check password errors.");
            }

            setIsLoading(false);
        }
        else {
            toast.error("Invalid code. \n Redirecting..");
            setTimeout(() => {
                window.location = "/password-reset"
            }, 2000)
        }
    }

    let handlePasswordChecks = async () => {
        if (!password) {
            setPasswordError(true);
            return false;
        }
        else {
            setPasswordError(false);
        }
        if (!confirmPassword) {
            setConfirmPasswordError(true);
            return false;
        }
        else {
            setConfirmPasswordError(false)
        }
        if (password != confirmPassword) {
            setConfirmPasswordError2(true)
            return false;
        }
        else {
            setConfirmPasswordError2(false)
        }

        return true
    }
    useEffect(() => {
        if (code) {
            if (!codeVerified) {
                handleVerifyCode();
            }

        }
        handlePasswordChecks()
    })
    return <>
        {!code ?
            < div style={{ background: `url(${TreeImg})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className="flex h-screen justify-center flex-col items-center lota-font font-lota">
                <p className="p-2 bg-white rounded-md "><FontAwesomeIcon className="text-rouzo-base-color text-sm" icon={faInfoCircle}></FontAwesomeIcon> Input your email address to initiate your password reset</p>
                <br></br>

                <div className="flex flex-col bg-rouzo-base-color shadow-2xl rounded-2xl p-8  items-center">

                    <div className="flex flex-col gap-4">
                        <p className="text-white font-bold text-xl">Password Reset</p>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col w-full text-left">
                                <div className="text-left">
                                    <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Email address</span>
                                </div>
                                <InputComponent onChange={(e) => setEmailAddress(e.target.value)} className="w-full" placeholder="Email address" ></InputComponent>
                            </div>


                        </div>
                        <div className="flex flex-row justify-end">
                            <div onClick={() => handleInitiateReset()} className={` flex flex-row rounded-md font-bold px-2 gap-2 items-center py-1 ${!isLoading ? "hover:cursor-pointer bg-white" : "hover:cursor-cancel bg-gray-200 text-xs font-regular"}`}>
                                <p>{isLoading ? "Generating password reset code" : "Reset"}</p>
                                {isLoading ?
                                    <FontAwesomeIcon className="h-4 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin />
                                    : ""
                                }
                            </div>
                        </div>

                    </div>


                </div>
                <div className="mt-6 bg-white p-2 rounded-full">
                    <p className="text-xs">New here ? <Link className="underline underline-offset-1" to={"/onboard"}>Signup</Link></p>
                </div>
            </div >
            :

            ""}
        {code ?
            < div style={{ background: `url(${TreeImg})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className="flex h-screen justify-center flex-col items-center lota-font font-lota">
                <p className="p-2 bg-white rounded-md "><FontAwesomeIcon className="text-rouzo-base-color text-sm" icon={faInfoCircle}></FontAwesomeIcon> Put in your new password to complete the reset.</p>
                <br></br>

                <div className="flex flex-col bg-rouzo-base-color shadow-2xl rounded-2xl p-8  items-center">

                    <div className="flex flex-col gap-4">
                        <p className="text-white font-bold text-xl">Password Reset</p>
                        <div className="flex flex-col md:flex-row gap-4">
                            {codeVerified == true ?
                                <>
                                    <div className="flex flex-col w-full text-left">
                                        <div className="text-left">
                                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Password</span>
                                        </div>
                                        <InputComponent onChange={(e) => setPassword(e.target.value)} className="w-full" placeholder="Password" ></InputComponent>
                                        {passwordError ? <TinyErrorComponent msg={"Put in your new password"}></TinyErrorComponent> : ""}
                                    </div>
                                    <div className="flex flex-col w-full text-left">
                                        <div className="text-left">
                                            <span className="font-bold text-xs text-left rounded-xl text-black bg-white px-2">Confirm Password</span>
                                        </div>
                                        <InputComponent onChange={(e) => setConfirmPassword(e.target.value)} className="w-full" placeholder="Confirm password" ></InputComponent>
                                        {confirmPasswordError ? <TinyErrorComponent msg={"repeat your new password"}></TinyErrorComponent> : ""}
                                        {confirmPasswordError2 ? <TinyErrorComponent msg={"passwords do not match"}></TinyErrorComponent> : ""}
                                    </div>
                                </>
                                :
                                ""}



                        </div>
                        <div className="flex flex-row justify-end">
                            {codeVerified ?
                                <div onClick={() => handlePasswordReset()} className={` flex flex-row rounded-md font-bold px-2 gap-2 items-center py-1 ${!isLoading ? "hover:cursor-pointer bg-white" : "hover:cursor-cancel bg-gray-200 text-xs font-regular"}`}>
                                    <p>{isLoading ? "Processing" : "Confirm Password Reset"}</p>
                                    {isLoading ?
                                        <FontAwesomeIcon className="h-4 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin />
                                        : ""
                                    }
                                </div>
                                : <div>
                                    <p className="text-white font-bold">{isLoading ? "Verifying password reset link" : "Password reset link needs to be verified"}</p>
                                    {isLoading ? <FontAwesomeIcon className="h-6 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin /> : ""}
                                </div>
                            }
                        </div>

                    </div>


                </div>
                <div className="mt-6 bg-white p-2 rounded-full">
                    <p className="text-xs">New here ? <Link className="underline underline-offset-1" to={"/onboard"}>Signup</Link></p>
                </div>
            </div >
            : ""
        }
    </>
}


export default PasswordResetPage
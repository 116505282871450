import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import AuthService from "../api/services/AuthService";
import { useAuthContext } from "../hooks/useAuth";
const useOnboard = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);
    const [error, setError] = useState(null);

    const onboard = async (userdata) => {
        setIsLoading(true);
        setError(null);
        console.log(userdata);
        try {
            const res = await AuthService.onboard(userdata);
            console.log("Response should be made with : ", res);
            let { success, msg } = res;
            console.log(res);
            if (success) {
                let { data } = res;
                console.log(data)
                //console.log(authorization);

                toast.success(`${msg}`);
                setInterval(() => {
                    navigate(`/verify/pending/${userdata.emailAddress}`)
                }, 2000);
                //fetchAllAccounts();

            }
            else {
                toast.error(msg)
            }
        } catch (error) {
            //setIsLoading(false);
            //##TODO: ERROR TOAST

            //errorToast(error.message);
            let { response } = error;
            if (response) {
                let { data } = response;
                if (data) {
                    if (data.message) {
                        toast.error(data.error);
                    }
                }
            }
            else {
                toast.error("An error occured");
            }

            console.log(error)
        }
    };

    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        onboard,
    };
};

export default useOnboard;

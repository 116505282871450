import { faArrowAltCircleDown, faArrowDown, faArrowCircleLeft, faArrowUpFromBracket, faInfoCircle, faMoneyBill, faArrowAltCircleUp, faUserCircle, faUser, faSortDown, faSortUp, faCrown, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

import { useContext, useEffect, useState } from "react";

import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
let LeaderboardPage = () => {
    let [name, setName] = useState("");
    let [userPoints, setUserPoints] = useState(null);
    let [isLoading, setIsLoading] = useState(false);
    const { fetchRankings } = useAccounts();
    //let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    const { rankings, setRankings, yourPosition, setYourPosition } = useContext(AccountContext);
    let [challengeName, setChallengeName] = useState("agbaballer");

    let [firstPosition, setFirstPosition] = useState(null);
    let [secondPosition, setSecondPosition] = useState(null);
    let [thirdPosition, setThirdPosition] = useState(null);
    let [fourthPosition, setFourthPosition] = useState(null);
    let [fifthPosition, setFifthPosition] = useState(null);
    let [] = useState(null);

    useEffect(() => {
        if (!rankings) {
            handleRankings(challengeName)
        }
        if(rankings){
            if(rankings.length > 0){
                console.log("Loading.....");
                setFirstPosition(rankings[rankings.length - 1]);
                setSecondPosition(rankings[rankings.length -2]);
                setThirdPosition(rankings[rankings.length - 3]);
                setFourthPosition(rankings[rankings.length -4]);
                setFifthPosition(rankings[rankings.length - 5]);
                setYourPosition(yourPosition);
            }
            else{
                setFirstPosition(null);
                setSecondPosition(null);
                setThirdPosition(null);
                setFourthPosition(null);
                setFifthPosition(null);
                setYourPosition(null);
            }
        }
    },[rankings])

    let handleRankings = async (challengeName) => {
        if (isLoading) {
            return;
        }
        setRankings(null);
        setIsLoading(true);
        let _rankData = await fetchRankings(challengeName);
        if (_rankData.success) {

            console.log("RankData: ",_rankData);
            console.log("Rankings:: ",rankings);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000)
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 2000)

    }

    let handleLeaderboardChange = (e) => {

        console.log(e.target.value)
        handleRankings(e.target.value);
    }
    return <>
        {/*{isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive}></NewSavingPlan> : ""}*/}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto ">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Leaderboard</p>
                    </div>
                </div>
                <div className="">
                    <div style={{ backgroundImage: "linear-gradient(to right bottom, #8430de, #7126d5, #5d1dcc, #4513c4, #2709bb)" }} className="rounded-b-2xl flex flex-col p-4 pb-8 px-8">
                        <div className="flex flex-row justify-center text-center">
                            <select onChange={(e) => handleLeaderboardChange(e)} className="bg-rouzo-green-light rounded-md px-2 font-bold text-xs text-white">
                                <option selected value="agbaballer">agbaballer</option>
                                <option value="odogwu">odogwu</option>
                                <option value="echoke">echoke</option>
                                <option value="profprof">profprof</option>
                                <option value="deyplay">deyplay</option>
                                <option value="atallatall">atallatall</option>
                            </select>

                        </div>
                        <div className="rounded-xl ">

                        </div>
                        <div className="flex flex-row pt-24 justify-center ">
                            {/**SECOND POSITION */}
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col justify-center">
                                    <p className="font-bold text-white text-xs">2<br></br><FontAwesomeIcon className="relative  text-orange-500" icon={faSortUp}></FontAwesomeIcon></p>
                                </div>
                                <div style={{ backgroundImage: 'linear-gradient(to right bottom, #0fc143, #48cc3b, #6bd731, #8ae124, #a8eb12)' }} className="rounded-full  drop-shadow-md">
                                    <FontAwesomeIcon className="text-[100px] text-[#3f11c2]" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                <div>
                                    {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                        <p className="text-sm text-white">{secondPosition ? `@${secondPosition.name}` : "@-"}</p>

                                        <p className="font-bold text-2xl text-rouzo-green-light ">{secondPosition ? secondPosition.rankPoints : "-"}</p>
                                    </>}

                                </div>
                            </div>
                            {/**FIRST POSITION */}
                            <div className="relative -top-20 flex flex-col gap-4">
                                <div className="flex flex-col justify-center">
                                    <FontAwesomeIcon className="text-rouzo-green-light font-bold text-[40px]" icon={faCrown}></FontAwesomeIcon>
                                </div>
                                <div style={{ backgroundImage: 'linear-gradient(to right bottom, #0fc143, #48cc3b, #6bd731, #8ae124, #a8eb12)' }} className="rounded-full drop-shadow-md">
                                    <FontAwesomeIcon className="text-[150px] text-[#3f11c2]" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                <div>
                                    {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                        <p className="text-sm text-white">{firstPosition ? `@${firstPosition.name}` : "@-"}</p>

                                        <p className="font-bold text-rouzo-green-light text-2xl">{firstPosition ? firstPosition.rankPoints : "-"}</p>
                                    </>}
                                </div>
                            </div>
                            {/**THIRD POSITION */}
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col justify-center">
                                    <p className="font-bold text-white text-xs">3<br></br><FontAwesomeIcon className="relative -top-2 text-rose-400" icon={faSortDown}></FontAwesomeIcon></p>

                                </div>
                                <div style={{ backgroundImage: 'linear-gradient(to right bottom, #0fc143, #48cc3b, #6bd731, #8ae124, #a8eb12)' }} className="rounded-full  drop-shadow-md">
                                    <FontAwesomeIcon style={{ backgroundImage: 'linear-gradient(to right bottom, #0fc143, #48cc3b, #6bd731, #8ae124, #a8eb12)' }} className=" rounded-full text-[100px] text-[#3f11c2]" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                <div>
                                    {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                        <p className="text-sm text-white">{thirdPosition ? `@${thirdPosition.name}` : "@-"}</p>

                                        <p className="font-bold text-rouzo-green-light text-2xl">{thirdPosition ? thirdPosition.rankPoints : "-"}</p>
                                    </>}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="py-4 flex flex-col gap-4">
                        <div className="flex flex-row justify-between px-4 py-4 rounded-xl  bg-[#3f11c2] items-center drop-shadow-md">
                            <div className="flex flex-row items-center gap-4 ">
                                <p className="font-bold text-sm text-white">4</p>
                                <div className="rounded-full ">
                                    <FontAwesomeIcon className="text-3xl bg-white rounded-full text-rouzo-green-2" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-white font-bold">{fourthPosition ? `@${fourthPosition.name}` : "@-"}</p>
                                </>}
                            </div>
                            <div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-rouzo-green-light font-bold text-2xl">{fourthPosition ? fourthPosition.rankPoints : "-"}</p>
                                </>}
                            </div>
                        </div>
                        <div className="flex flex-row justify-between px-4 py-4 rounded-xl  bg-[#3f11c2] items-center drop-shadow-md">
                            <div className="flex flex-row items-center gap-4 ">
                                <p className="font-bold text-sm text-white">5</p>
                                <div className="rounded-full ">
                                    <FontAwesomeIcon className="text-3xl bg-white rounded-full text-rouzo-green-2" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-white font-bold">{fifthPosition ? `@${fifthPosition.name}` : "@-"}</p>
                                </>}
                            </div>
                            <div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-rouzo-green-light font-bold text-2xl">{fifthPosition ? fifthPosition.rankPoints : "-"}</p>
                                </>}
                            </div>
                        </div>

                        <div style={{ boxShadow: "0px -1px 10px 2px #822fdd" }} className="flex flex-row justify-between px-4 py-4 rounded-xl  bg-[#3f11c2] items-center drop-shadow-md">
                            <div className="flex flex-row items-center gap-4 ">
                                <p className="font-bold text-sm text-white">{yourPosition ? yourPosition.position ? yourPosition.position : "-" : "-"}</p>
                                <div className="rounded-full ">
                                    <FontAwesomeIcon className="text-3xl bg-white rounded-full text-rouzo-green-2" icon={faUserCircle}></FontAwesomeIcon>
                                </div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-white  text-sm font-bold">@You</p>
                                </>}
                            </div>
                            <div>
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : <>
                                    <p className="text-rouzo-green-light text-2xl font-bold">{yourPosition ? yourPosition.rankPoints : "-"}</p>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}


export default LeaderboardPage
import { useState, React, createContext } from "react";


export const AccountContext = createContext();

// Create an exportable consumer that can be injected into components
export const AccountConsumer = AccountContext.Consumer
// Create the provider using a traditional React.Component class

const AccountProvider = ({children}) => {
    
    let [savingHistory, setSavingHistory] = useState([]);
    let [user, setUser] = useState({});
    let [userId, setUserId] = useState("");
    let [fullName, setFullName] = useState("");
    let [stashBalance , setStashBalance] = useState(0);
    let [savingsBalance , setSavingsBalance] = useState(0);
    let [savingPlans , setSavingPlans] = useState(null);
    let [transactions, setTransactions] = useState(null);
    let [transfers, setTransfers] = useState({});
    let [purchase , setPurchase] = useState({});
    let [savings , setSavings] = useState({});
    let [wallet , setWallet] = useState({});
    let [isStash , setIsStash] = useState(false);
    let [isAllowed, setIsAllowed] = useState(false);
    let [banks, setBanks] = useState(null);
    let [warnedBanks, setWarnedBanks] = useState(false);
    let [miniTransactions, setMiniTransactions] = useState(null)
    let [funds, setFunds] = useState(0);
    let [referralSlug , setReferralSlug] = useState("");
    let [rankings, setRankings] = useState(null);
    let [firstDayRank, setFirstDayRank] = useState(null);
    let [lastDayRank, setLastDayRank] = useState(null)
    let [yourPosition, setYourPosition] = useState(null);
    let [referrals, setReferrals] = useState(null);
    let [referralEarnings, setReferralEarnings] = useState(null);
    let [activeSavingsBalance , setActiveSavingsBalance] = useState(0);
   
    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AccountContext.Provider value={{
                
                savingHistory, setSavingHistory,
                
                userId, setUserId,
                user, setUser,
                stashBalance, setStashBalance,
                savingsBalance, setSavingsBalance,
                miniTransactions, setMiniTransactions,
                transactions, setTransactions,
                transfers, setTransfers,
                purchase, setPurchase,
                savings, setSavings,
                isStash, setIsStash,
                isAllowed, setIsAllowed,
                referralSlug , setReferralSlug,
                savingPlans, setSavingPlans,
                wallet,setWallet,
                fullName,setFullName,
                banks, setBanks,
                warnedBanks, setWarnedBanks,
                lastDayRank, setLastDayRank,
                firstDayRank, setFirstDayRank,
                rankings, setRankings,
                yourPosition, setYourPosition,
                referrals,setReferrals,
                referralEarnings, setReferralEarnings,
                activeSavingsBalance , setActiveSavingsBalance
            }}>
            {children}
        </AccountContext.Provider>
    )
}
export default AccountProvider
